import * as Yup from "yup";
import { validation } from "formUtils/validation";

export const paiMemberShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  expectedIncome: validation.numberRequired,
  adjustmentsIncome: validation.numberRequired,
  projectedAnnualIncome: validation.numberRequired,
};

export const validationSchema = Yup.object().shape({
  paiMembers: Yup.array().of(Yup.object().shape(paiMemberShape)),
});
