import React from "react";
import {
  FormikBirthdayPickerField,
  FormikInputField,
  FormikRadioButtonField,
  FormRow,
} from "components";
import { yesNoOptions } from "components/pages/DhsPage/Forms/IncomeTypeForm/formUtils";

import { GridRow2Cols, GridRow3Cols } from "./ImmigrationForm.css";
import { GridContainer } from "../Forms.css";

interface HouseholdMemberRowProps {
  index: number;
  hadDifferentStatus: boolean | null;
  hasCitizenship: boolean | null;
  hasImmigrationStatus: boolean | null;
}

export const ImmigrationMemberRow = ({
  index,
  hadDifferentStatus,
  hasCitizenship,
  hasImmigrationStatus,
}: HouseholdMemberRowProps) => {
  return (
    <FormRow key={index}>
      <GridContainer>
        <GridRow3Cols>
          <FormikInputField
            disabled
            label="first_name"
            name={`immigrationMembersList.${index}.name`}
          />
        </GridRow3Cols>
        <GridRow3Cols>
          <FormikRadioButtonField
            extraLabel="are_you_us_citizen"
            name={`immigrationMembersList.${index}.hasCitizenship`}
            options={yesNoOptions}
          />
        </GridRow3Cols>
        {hasCitizenship === false && (
          <GridRow3Cols>
            <FormikRadioButtonField
              extraLabel="has_immigration_status"
              name={`immigrationMembersList.${index}.hasImmigrationStatus`}
              options={yesNoOptions}
            />
          </GridRow3Cols>
        )}
      </GridContainer>
      {hasImmigrationStatus && !hasCitizenship && (
        <>
          <GridContainer>
            <GridRow2Cols>
              <FormikInputField
                label="immigration_document_type"
                name={`immigrationMembersList.${index}.documentType`}
              />
            </GridRow2Cols>
            <GridRow2Cols>
              <FormikInputField
                label="alien_id_number"
                name={`immigrationMembersList.${index}.alienId`}
              />
            </GridRow2Cols>
          </GridContainer>
          <GridContainer>
            <GridRow2Cols>
              <FormikBirthdayPickerField
                label="document_expiration_date"
                name={`immigrationMembersList.${index}.expirationDate`}
              />
            </GridRow2Cols>
            <GridRow2Cols>
              <FormikBirthdayPickerField
                label="date_of_entry"
                name={`immigrationMembersList.${index}.entryDate`}
              />
            </GridRow2Cols>
          </GridContainer>
          <GridContainer>
            <GridRow2Cols>
              <FormikRadioButtonField
                extraLabel="enter_us_before_aug1996"
                name={`immigrationMembersList.${index}.hasEnteredBeforeDate`}
                options={yesNoOptions}
              />
            </GridRow2Cols>
            <GridRow2Cols>
              <FormikRadioButtonField
                extraLabel="lived_in_qualified_status"
                name={`immigrationMembersList.${index}.hasLivedBeforeWithStatus`}
                options={yesNoOptions}
              />
            </GridRow2Cols>
          </GridContainer>
          <GridContainer>
            <GridRow2Cols>
              <FormikRadioButtonField
                extraLabel="have_different_immigration_status"
                name={`immigrationMembersList.${index}.hadDifferentStatus`}
                options={yesNoOptions}
              />
            </GridRow2Cols>
          </GridContainer>

          {hadDifferentStatus && (
            <GridContainer>
              <GridRow3Cols>
                <FormikInputField
                  label="previous_code_status"
                  name={`immigrationMembersList.${index}.previousStatusCode`}
                />
              </GridRow3Cols>
              <GridRow3Cols>
                <FormikBirthdayPickerField
                  label="previous_entry_date"
                  name={`immigrationMembersList.${index}.previousEntryDate`}
                />
              </GridRow3Cols>
            </GridContainer>
          )}
        </>
      )}
    </FormRow>
  );
};
