import React from "react";
import { FieldArray, Formik } from "formik";
import { FormActionButtons } from "components";
import { PaiModel } from "models";
import { validationSchema } from "./formUtils";
import { Form, FormContent, MemberRows } from "../Forms.css";
import { PaiMemberRow } from "./PAIMemberRow";
import { PaiFormContent } from "components/pages/DhsPage/Forms/PAIForm/PAIForm.css";

export interface PAIFormFormType {
  paiMembers: PaiModel[];
}

interface PAIFormProps {
  defaultValues: PAIFormFormType;
  onBack: () => void;
  onSubmit: (values: PAIFormFormType) => void;
}

export const PAIForm = ({ onBack, defaultValues, onSubmit }: PAIFormProps) => {
  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, handleSubmit, ...rest }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <PaiFormContent>
            <FieldArray name="paiMembers">
              {() => {
                return (
                  <FormContent>
                    <MemberRows>
                      {values?.paiMembers?.map((member, index: number) => {
                        return <PaiMemberRow key={member.id} index={index} />;
                      })}
                    </MemberRows>
                  </FormContent>
                );
              }}
            </FieldArray>
          </PaiFormContent>
          <FormActionButtons onBack={onBack} />
        </Form>
      )}
    </Formik>
  );
};
