import { validation } from "formUtils/validation";
import * as Yup from "yup";
import { DocumentTypeEnum } from "enums";
import { errorsMessage } from "formUtils/errorMessages";
import { imageMaxSize } from "constants/imageMaxSize";

export const documentTypeOptions = [
  { uuid: "prove_of_income", name: DocumentTypeEnum.PROOF_OF_INCOME },
  { uuid: "immigration_status", name: DocumentTypeEnum.IMMIGRATION_STATUS },
  { uuid: "immigration_document", name: DocumentTypeEnum.IMMIGRATION_DOCUMENT },
  { uuid: "signature_page", name: DocumentTypeEnum.SIGNATURE_PAGE },
  { uuid: "government_id", name: DocumentTypeEnum.GOVERNMENT_ID },
  { uuid: "other", name: DocumentTypeEnum.OTHER },
];

export const documentTypeByKey = {
  PROOF_OF_INCOME: DocumentTypeEnum.PROOF_OF_INCOME,
  IMMIGRATION_STATUS: DocumentTypeEnum.IMMIGRATION_STATUS,
  IMMIGRATION_DOCUMENT: DocumentTypeEnum.IMMIGRATION_DOCUMENT,
  SIGNATURE_PAGE: DocumentTypeEnum.SIGNATURE_PAGE,
  GOVERNMENT_ID: DocumentTypeEnum.GOVERNMENT_ID,
  OTHER: DocumentTypeEnum.OTHER,
};

export const documentKeyByType: Record<string, string> = Object.fromEntries(
  Object.entries(documentTypeByKey).map(([key, value]) => [value, key]),
);

const documentListTypeShape = {
  documentDescription: validation.stringNotRequired,
  documentUploadType: validation.stringRequired,
  file: Yup.mixed()
    .required(errorsMessage.required)
    .test("fileSize", "image_max_size", (value) => {
      const file = value as File;
      return file.size <= imageMaxSize;
    }),
};

export const documentMembersTypeShape = {
  name: validation.stringRequired,
  documentsList: Yup.array().of(Yup.object().shape(documentListTypeShape)),
};

export const validationSchema = Yup.object().shape({
  uploadDocumentsMembers: Yup.array().of(
    Yup.object().shape(documentMembersTypeShape),
  ),
});
