import React from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";

import { FormActionButtons, FormikBirthdayPicker, Input } from "components";
import { AssisterModel } from "models";

import { validationSchema } from "./formUtils";
import { Form, GridContainer } from "../Forms.css";
import { Content } from "./AssisterForm.css";

interface ApplicantFormProps {
  onSubmit: (values: AssisterModel) => void;
  onBack: () => void;
  defaultValues: AssisterModel;
}

export const AssisterForm = ({
  onSubmit,
  onBack,
  defaultValues,
}: ApplicantFormProps) => {
  const formik = useFormik<AssisterModel>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: defaultValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const link = (
    <a target="_blank" href="https://www.mnsure.org/" rel="noreferrer">
      MNsure.org
    </a>
  );
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Content>
        <p>
          This form gives all Briva Health’s MNsure-certified assisters the
          authority to create an account on {link} in your name and submit an
          application on your behalf. We are asking for this information about
          you so we can contact you if needed and to confirm your identity. The
          Information you provide is private, You are not legally required to
          provide this information and there are no negative consequences if you
          do not complete this form, though your assister will be unable to
          create an account and apply for health coverage on your behalf. MNsure
          staff will have access to this Information, as well as others with
          legal authority, MNsure will keep it for up to ten years. This privacy
          notice does not include the {link} Terms of Use and Privacy Notice if
          you provide MNsure with additional information to create an account or
          apply for health coverage. For more information about MNsure's Terms
          of Use and Privacy Warnings for MNsure participants, go to{" "}
          <a
            target="_blank"
            href="https://www.mnsure.org/resources/terms-conditions.jsp"
            rel="noreferrer"
          >
            www.mnsure.org/resources/terms-conditions.jsp
          </a>
          .
        </p>

        <p>
          By completing and signing this form, you are authorizing Any Briva
          Health assister to create an account for you on {link} because you do
          not have access to a computer and cannot meet in person with an
          assister. Creating an account is the first step to determine your
          eligibility for benefits and enrolling in health coverage. To create a
          new account, MNsure must collect enough Information to verify your
          Identity. Your assister will ask for this information from you to
          enter when he or she Is creating the account. Your Information is
          private and he or she must safeguard It. When your assister is
          creating your account, he or she must provide a privacy warning from
          MNsure about the Information being requested and ask if you agree to
          the MNsure terms and conditions. By asking an assister to create an
          account for you, you agree to use the system for authorized purposes
          only, In compliance with state and federal law, and agree to the
          MNsure Terms and Conditions. A copy of the privacy warning and the
          terms and conditions are available at
          <a
            target="_blank"
            href="https://www.mnsure.org/resources/terms-conditions.jsp"
            rel="noreferrer"
          >
            www.mnsure.org/resources/terms-conditions.jsp
          </a>
          . Your assister cannot create your account or submit an application on
          your behalf if you do not agree.
        </p>

        <p>
          {" "}
          By completing and signing this form, you are authorizing any Briva
          Health assister to act on your behalf and on behalf of any others on
          your application for the following actions:
          <ul>
            <li>Access your data to provide customer service</li>
            <li>
              Enter Information about you or your household into an application
            </li>
            <li>Submit an application for you</li>
            <li>Select a qualified health plan</li>
            <li>Select and apply advanced premium tax credit amount</li>
          </ul>
        </p>

        <p>
          You are also authorizing Briva assisters to retain your login
          information, provide and view Information on all the people on your
          application. MNsure applicants or assisters who provide and view
          Information on behalf of applicant or household members, dependents,
          employees or others verify that they have the permission of the
          Individual data subject, or are the legal guardian, or are otherwise
          authorized to access and submit the information, and must agree to
          safeguard it. Individuals who view or submit Information on behalf of
          another Individual also agree to only use the personally Identifiable
          Information for the purpose of completing the proper application or as
          otherwise allowed by state and federal law and to safeguard the data
          from unauthorized access, use, modification, destruction, theft or
          disclosure. The information on an application is private data
        </p>

        <p>
          By signing below, you are allowing Briva assisters to receive
          information about your application and act on matters related to this
          application, including signing your application on your behalf and
          enrolling in a qualified health plan on your behalf. You also
          acknowledge that you are still responsible for meeting all applicable
          deadlines for enrolling In coverage,
        </p>

        <p>
          Before agreeing, please review the MNsure Privacy Warnings, which
          describe the purpose and Intended use for collecting private data on
          your application and whether there are consequences for refusing to
          provide the data. The privacy warnings also define the individuals and
          organizations with whom your Information may be shared and how long
          MNsure will keep your information. More Information about your privacy
          rights and responsibilities Is located on the Terms and Privacy page
          of {link}.
        </p>

        <p>
          You can end this authorization at any time through your MNsure online
          account or by calling the MNsure Contact Center
        </p>
        <GridContainer>
          <Grid item xs={12} md={6}>
            <Input label="name" name="name" formik={formik} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikBirthdayPicker label="date" name="date" formik={formik} />
          </Grid>
        </GridContainer>
      </Content>
      <FormActionButtons onBack={onBack} />
    </Form>
  );
};
