import i18n from "i18n";
import {
  AdjustmentIncomeModel,
  AssisterModel,
  CurrentJobModel,
  DetailJobMemberModel,
  IncomeTypeModel,
  OtherIncomeSourceModel,
} from "models";
import { CREATE_ENROLLMENT_vars } from "graphql/mutation/createEnrollment";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { calculateSum } from "utils/numbers";
import { safeDelete } from "utils/objects";

export const hasAnyoneDetailedTypeJob = (
  incomeTypeData: IncomeTypeModel[],
): boolean => {
  return incomeTypeData?.some((m) => {
    const currentJob = "no" !== m.currentOrPartJob;
    const hasSeasonalJob = m.hasSeasonalJob;
    const isSelfEmployed = m.isSelfEmployed;
    const otherIncomeSource = i18n.t("no") !== m.otherIncomeSource;
    return currentJob || hasSeasonalJob || isSelfEmployed || otherIncomeSource;
  });
};

export const applyAnnualIncomeFormulaByWage = (
  wage: string,
  amount: number,
  hoursPerWeek?: number,
) => {
  let yearlyIncome = 0;
  switch (wage) {
    case i18n.t("hourly"):
      yearlyIncome = amount * (hoursPerWeek ?? 0) * 52;
      break;
    case i18n.t("weekly"):
      yearlyIncome = amount * 52;
      break;
    case i18n.t("every_two_weeks"):
      yearlyIncome = amount * 26;
      break;
    case i18n.t("twice_a_month"):
      yearlyIncome = amount * 2 * 12;
      break;
    case i18n.t("monthly"):
      yearlyIncome = amount * 12;
      break;
    case i18n.t("yearly"):
      yearlyIncome = amount;
      break;
  }

  return yearlyIncome;
};

export const getCurrentJobYearlyIncome = (
  currentOrPartJobs: CurrentJobModel[],
) => {
  if (!currentOrPartJobs?.length) return 0;

  const calculateCurrentJobAnnualIncome = currentOrPartJobs?.map((i) => {
    const amount = Number(i.amount);
    const hoursPerWeek = Number(i.hoursPerWeek);
    const wage = i.wage;
    return applyAnnualIncomeFormulaByWage(wage, amount, hoursPerWeek);
  });

  return calculateSum(calculateCurrentJobAnnualIncome);
};

export const getOtherIncomeAnnual = (otherIncome: OtherIncomeSourceModel) => {
  const otherIncomeWage = otherIncome?.howOftenReceived;
  const otherIncomeAmount = otherIncome?.amount ?? 0;
  const totalOtherIncome = applyAnnualIncomeFormulaByWage(
    otherIncomeWage,
    otherIncomeAmount,
  );
  const otherTaxableIncomes = otherIncome?.incomes?.map((ti) => {
    return applyAnnualIncomeFormulaByWage(ti.frequency, ti.amount ?? 0);
  });
  const otherTaxableIncomesAnnual = calculateSum(otherTaxableIncomes);
  return totalOtherIncome + otherTaxableIncomesAnnual;
};

export const calculatePaiMemberAnnualIncome = (
  detailJobMember: DetailJobMemberModel,
  adjustmentsIncomeMembers: AdjustmentIncomeModel[],
) => {
  const currentAdjustmentIncome = adjustmentsIncomeMembers?.find(
    (m) => m.id === detailJobMember.id,
  );

  const adjustmentsIncome = currentAdjustmentIncome?.yearlyAmount ?? 0;
  const currentJobYearlyIncome = getCurrentJobYearlyIncome(
    detailJobMember?.currentJobs,
  );

  const seasonJobIncomeList = detailJobMember?.seasonalJobs?.map(
    (s) => s.seasonalIncome ?? 0,
  );
  const seasonJobYearlyIncome = detailJobMember.seasonalJobs?.length
    ? calculateSum(seasonJobIncomeList)
    : 0;
  const selfEmployedYearlyIncome = detailJobMember?.selfEmployment
    ? (detailJobMember.selfEmployment.incomeAmount ?? 0) -
      (detailJobMember?.selfEmployment?.lossAmount ?? 0)
    : 0;
  const otherYearlyIncome = detailJobMember.otherIncomeSource
    ? getOtherIncomeAnnual(detailJobMember.otherIncomeSource)
    : 0;
  const expectedIncome =
    currentJobYearlyIncome +
    seasonJobYearlyIncome +
    selfEmployedYearlyIncome +
    otherYearlyIncome;
  const projectedAnnualIncome = expectedIncome - adjustmentsIncome ?? 0;

  return {
    expectedIncome,
    adjustmentsIncome,
    projectedAnnualIncome,
  };
};

const consentYears = {
  do_not_use_information_tax: 0,
  "1_year": 1,
  "2_years": 2,
  "3_years": 3,
  "4_years": 4,
  "5_years": 5,
};

const generateHouseholdMembersServer = (data: GeneralForm | null) => {
  const copyData = JSON.parse(JSON.stringify(data)) as GeneralForm;

  return data?.[2]?.members.map((householdMember) => {
    const pregnancy = copyData?.[3]?.pregnancy.find(
      (p) => p.id === householdMember.id,
    );
    const taxFiling = copyData?.[4]?.taxFillingMembers.find(
      (t) => t.id === householdMember.id,
    );
    const incomeQuestions = copyData?.[5]?.incomeMembers.find(
      (i) => i.id === householdMember.id,
    );
    const incomeTypes = copyData?.[6]?.incomeTypeMembers.find(
      (it) => it.id === householdMember.id,
    );
    const detailJobInfo = copyData?.[7]?.detailJobMembers.find(
      (d) => d.id === householdMember.id,
    );
    const adjustmentIncome = copyData?.[8]?.adjustmentIncomeMembers.find(
      (ai) => ai.id === householdMember.id,
    );
    const pai = copyData?.[9]?.paiMembers.find(
      (p) => p.id === householdMember.id,
    );
    const healthCoverage = copyData?.[10]?.healthCoverageMembers.find(
      (hc) => hc.id === householdMember.id,
    );
    const immigration = copyData[11]?.immigrationMembersList?.find(
      (i) => i.id === householdMember.id,
    );

    const paiPlaceholder = {
      name: `${householdMember.firstName} ${householdMember.lastName}`,
      expectedIncome: 0,
      adjustmentsIncome: 0,
      projectedAnnualIncome: 0,
    };

    // remove fields which do not need for BE
    safeDelete(pregnancy, "id");
    safeDelete(pregnancy, "name");
    safeDelete(taxFiling, "id");
    safeDelete(taxFiling, "name");
    safeDelete(incomeQuestions, "id");
    safeDelete(incomeTypes, "id");
    safeDelete(detailJobInfo, "id");
    safeDelete(adjustmentIncome, "id");
    safeDelete(pai, "id");
    safeDelete(healthCoverage, "id");
    safeDelete(immigration, "id");

    return {
      firstName: householdMember.firstName,
      middleName: householdMember.middleName,
      lastName: householdMember.lastName,
      birthday: householdMember.birthday,
      maritalStatus: householdMember.maritalStatus,
      relationship: householdMember.relationship,
      pregnancy,
      taxFiling: {
        ...taxFiling,
        taxFilingStatus: taxFiling?.taxFilingStatus.toUpperCase(),
      },
      incomeQuestions,
      incomeTypes: incomeTypes
        ? {
            ...incomeTypes,
            ...(incomeTypes.currentOrPartJob && {
              currentOrPartJob: incomeTypes.currentOrPartJob.toUpperCase(),
            }),
          }
        : null,
      currentJobs: detailJobInfo?.currentJobs ?? [],
      selfEmployment: detailJobInfo?.selfEmployment ?? null,
      seasonalJobs: detailJobInfo?.seasonalJobs ?? [],
      otherIncomeSources: detailJobInfo?.otherIncomeSource ?? null,
      adjustmentIncome: adjustmentIncome ?? null,
      pai: pai ?? paiPlaceholder,
      healthCoverage: [healthCoverage],
      immigrationForm: immigration,
    };
  });
};

export const prepareDataForRequest = (
  data: GeneralForm | null,
  values: AssisterModel,
) => {
  const agreementDuration = data?.[13]
    ?.agreementDuration as keyof typeof consentYears;

  const renewalTaxReturnConsentYears = consentYears[agreementDuration];
  const renewalTaxReturnConsent = consentYears[agreementDuration] !== 0;

  return {
    enrollmentData: {
      sameAnnualIncome: false,
      applicant: {
        ...data?.[1],
        caseNumber: null,
        alternativeStreetAddress: null,
        alternativeCity: null,
        alternativeState: null,
        alternativeZipCode: null,
        alternativeCounty: null,
        phoneType: null,
        otherPhoneType: null,
        otherPhoneNumber: null,
        contactViaEmail: false,
        email: null,
        allowTextMessage: false,
      },
      householdMembers: generateHouseholdMembersServer(data),
      followingQuestions: data?.[12],
      assister: {
        nameAssister: values.name,
        applicationStartDate: values.date,
      },
      sign: {
        renewalTaxReturnConsent,
        renewalTaxReturnConsentYears,
      },
    },
  } as CREATE_ENROLLMENT_vars;
};

/**
 * Fields were removed from forms and set null by default: (might be removed from BE in the future)
 * Applicant: caseNumber, alternativeStreetAddress, alternativeCity, alternativeState, alternativeZipCode,
 * alternativeCounty, phoneType, otherPhoneType, otherPhoneNumber, contactViaEmail, email, allowTextMessage
 * Household: ssn
 * Pai: sameAnnualIncome
 */
