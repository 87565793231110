import * as Yup from "yup";
import { validation } from "formUtils/validation";

export const memberShape = {
  name: validation.stringRequired,
  hasCitizenship: validation.booleanRequired,
  hasImmigrationStatus: Yup.boolean().when("hasCitizenship", {
    is: false,
    then: () => validation.booleanRequired,
    otherwise: () => validation.booleanNotRequired,
  }),
  documentType: validation.stringNotRequired,
  alienId: validation.stringNotRequired,
  expirationDate: validation.stringNotRequired,
  entryDate: validation.stringNotRequired,
  hasEnteredBeforeDate: validation.booleanNotRequired,
  hasLivedBeforeWithStatus: validation.booleanNotRequired,
  hadDifferentStatus: validation.booleanNotRequired,
  previousStatusCode: validation.stringNotRequired,
  previousEntryDate: validation.dateNotRequired,
};

export const validationSchema = Yup.object().shape({
  immigrationMembersList: Yup.array().of(Yup.object().shape(memberShape)),
});
