import React from "react";
import { FieldArray, Formik } from "formik";

import { ImmigrationModel } from "models";
import { FormActionButtons } from "components";

import { ImmigrationMemberRow } from "./ImmigrationMemberRow";
import { StatusCodes } from "./StatusCodes";
import { validationSchema } from "./formUtils";
import { Form, FormContent, MemberRows } from "../Forms.css";

export interface ImmigrationFormType {
  immigrationMembersList: ImmigrationModel[];
}

interface ImmigrationFormProps {
  defaultValues: ImmigrationModel[];
  onSubmit: (values: ImmigrationFormType) => void;
  onBack: () => void;
}

export const ImmigrationForm = ({
  defaultValues,
  onSubmit,
  onBack,
}: ImmigrationFormProps) => {
  return (
    <Formik
      initialValues={{ immigrationMembersList: defaultValues }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, handleSubmit, errors }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <FieldArray name="immigrationMembersList">
            {() => {
              return (
                <FormContent>
                  <MemberRows>
                    {values?.immigrationMembersList?.map((member, index) => {
                      const hadDifferentStatus =
                        values.immigrationMembersList[index].hadDifferentStatus;
                      const hasImmigrationStatus =
                        values.immigrationMembersList[index]
                          .hasImmigrationStatus;

                      const hasCitizenship =
                        values.immigrationMembersList[index].hasCitizenship;

                      return (
                        <ImmigrationMemberRow
                          key={member.id}
                          index={index}
                          hadDifferentStatus={hadDifferentStatus}
                          hasCitizenship={hasCitizenship}
                          hasImmigrationStatus={hasImmigrationStatus}
                        />
                      );
                    })}
                  </MemberRows>
                  <StatusCodes />
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack} />
        </Form>
      )}
    </Formik>
  );
};
