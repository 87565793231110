import styled from "styled-components";
import { Grid } from "@mui/material";

export const GridRow2Cols = styled(Grid)(() => ({}));
GridRow2Cols.defaultProps = {
  item: true,
  xs: 12,
  md: 6,
  alignContent: "center",
};

export const GridRow3Cols = styled(Grid)(() => ({}));
GridRow3Cols.defaultProps = {
  item: true,
  xs: 12,
  md: 4,
  alignContent: "center",
};

export const StatusesContainer = styled("div")(() => ({
  marginTop: "10px;",
}));
