import dayjs from "dayjs";

/**
 * Returns boolean if date is empty
 * @param dateStr - string which has format mm/dd/yyy
 */
export const isValidDate = (dateStr: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateStr)) return false;

  const dateObject = new Date(dateStr);
  return !isNaN(dateObject.getTime());
};

export const getCurrentStrDate = (): string => {
  return dayjs(new Date()).format("YYYY-MM-DD");
};

export const formatToISODate = (value: string) => {
  const [month = "", day = "", year = ""] = value?.split("/");
  const date = `${year}-${month}-${day}`;
  return date.replace(/^--?/, "");
};

export const isoDateToFormattedString = (value: string) => {
  const [year = "", month = "", day = ""] = value?.split("-");
  return `${month}/${day}/${year}`;
};
