import React from "react";
import { useFormik } from "formik";
import i18n from "i18next";
import { Grid } from "@mui/material";

import {
  FormActionButtons,
  FormikBirthdayPicker,
  Input,
  ManualSelect,
  Select,
} from "components";
import { ApplicantModel } from "models";
import { maritalStatusOptions } from "constants/maritalStatuses";
import { usePersistentLang } from "hooks/usePersistentLang";

import { countyList, langList, validationSchema } from "./formUtils";
import { Form, FormContent, GridContainer } from "../Forms.css";
import { ApplicantInfo, GridContainerFirstRow } from "./ApplicantForm.css";

interface ApplicantFormProps {
  disableBackButton: boolean;
  onSubmit: (values: Partial<ApplicantModel>) => void;
  onBack: () => void;
  defaultValues: Partial<ApplicantModel>;
}

export const ApplicantForm = ({
  onSubmit,
  disableBackButton,
  onBack,
  defaultValues,
}: ApplicantFormProps) => {
  const [currentLang, setCurrentLang] = usePersistentLang();

  const formik = useFormik<Partial<ApplicantModel>>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: defaultValues,
    onSubmit: (values: Partial<ApplicantModel>) => {
      onSubmit(values);
    },
  });

  const changeLanguage = async (event: { target: { value: string } }) => {
    const lang = event.target.value;
    setCurrentLang(lang);
    await i18n.changeLanguage(lang);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormContent>
        <GridContainerFirstRow>
          <Grid item xs={12} md={4}>
            <ManualSelect
              fullWidth
              label="language"
              value={currentLang}
              onChange={changeLanguage}
              options={langList}
              settingKeyName={"uuid"}
            />
          </Grid>
        </GridContainerFirstRow>
        <ApplicantInfo>
          <GridContainer>
            <Grid item xs={12} md={4}>
              <Input label="first_name" name="firstName" formik={formik} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input label="middle_name" name="middleName" formik={formik} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input label="last_name" name="lastName" formik={formik} />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} md={4}>
              <FormikBirthdayPicker
                label="birthday_label"
                name="birthday"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label="marital_status"
                name="maritalStatus"
                formik={formik}
                options={maritalStatusOptions}
              />
            </Grid>
          </GridContainer>
        </ApplicantInfo>
        <GridContainer>
          <Grid item xs={12} md={6}>
            <Input label="phone_number" name="phoneNumber" formik={formik} />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12} md={4}>
            <Input
              label="street_address"
              name="streetAddress"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input label="city" name="city" formik={formik} />
          </Grid>
          <Grid item xs={12} md={1.66}>
            <Input label="state" name="state" formik={formik} />
          </Grid>
          <Grid item xs={12} md={1.66}>
            <Input label="zip_code" name="zipCode" formik={formik} />
          </Grid>
          <Grid item xs={12} md={1.66}>
            <Select
              options={countyList}
              label="county"
              name="county"
              formik={formik}
            />
          </Grid>
        </GridContainer>
      </FormContent>
      <FormActionButtons
        disableBackButton={disableBackButton}
        onBack={onBack}
      />
    </Form>
  );
};
