import React from "react";
import { Typography } from "components";
import { colors } from "styles/colors";

import { StatusesContainer } from "./ImmigrationForm.css";

const STATUSES = [
  "A. American Indian born in Canada (Immigration and Nationality Act [INA], section 289)*",
  "B. Amerasian noncitizen*",
  "C. Asylee*",
  "D. Conditional entrant (only granted before 1981)*",
  "E. Cuban or Haitian entrant*",
  "F. Withholding of removal or deportation being withheld under section 243(h) or 241(b)(3) of the INA*",
  "G. Refugee*",
  "H. Special Iraqi or Afghani immigrant*",
  "I. Victim of severe trafficking (LPR or T Visa)*",
  "J. Battered noncitizen*",
  "K. Lawful permanent resident (LPR) or conditional resident*",
  "L. Paroled for at least one year*",
  "M. Temporary nonimmigrant",
  "N. Deferred action for childhood arrivals",
  "O. Citizen of Marshall Islands, Micronesia or Palau*",
];
export const StatusCodes = () => {
  return (
    <StatusesContainer>
      <Typography color={colors.darkGrey} variant="subtitle">
        Immigration status codes:
      </Typography>

      <ul>
        {STATUSES.map((s) => (
          <li key={s}>
            <Typography color={colors.darkGrey} variant={"tiniest"}>
              {s}
            </Typography>
          </li>
        ))}
      </ul>
    </StatusesContainer>
  );
};
