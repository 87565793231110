import React from "react";
import { CurrentJobFields } from "components/pages/DhsPage/Forms/DetailsJobForm/DetailMemberJobRow/CurrentJobsForm/CurrentJobFields";
import { RemoveFormButton } from "components/pages/DhsPage/Forms/DetailsJobForm/DetailMemberJobRow/DetailMemberJobRow.css";
import { FormRow } from "components/FormRow";

interface Props {
  index: number;
  currentJobIndex: number;
  remove: () => void;
}
export const CurrentJobForm = ({ index, currentJobIndex, remove }: Props) => {
  return (
    <FormRow>
      <CurrentJobFields
        key={index}
        employerName={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.employerName`}
        employerStreetAddress={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.employerStreetAddress`}
        employerCity={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.employerCity`}
        employerZipCode={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.employerZipCode`}
        employerState={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.employerState`}
        wage={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.wage`}
        hoursPerWeek={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.hoursPerWeek`}
        amount={`detailJobMembers.${index}.currentJobs.${currentJobIndex}.amount`}
      />
      {currentJobIndex > 0 && (
        <RemoveFormButton variant="contained" type="button" onClick={remove}>
          Remove
        </RemoveFormButton>
      )}
    </FormRow>
  );
};
